<template>
  <b-card>
    <b-row class="blog-list-wrapper mt-1">
      <b-col
        v-for="data in optionsLocal.keterampilan"
        :key="data.id"
        md="12"
      >
        <b-card-text><h4>{{data.nama}}</h4></b-card-text>
        <div class="blog-list-wrapper mb-2">
          <span
            v-for="item in data.item"
            :key="item.id"
            md="5"
          >
            <b-button
              v-if="item.active"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mt-1 mr-1"
              @click="hapusKeterampilan(item.id, item.kategori_jenis_keterampilan_id)"
            >
              <feather-icon icon="CheckIcon" />
              {{item.nama}}
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="mt-1 mr-1"
              @click="pilihKeterampilan(item.id, item.kategori_jenis_keterampilan_id)"
            >
              {{item.nama}}
            </b-button>
          </span>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import storeModule from '@/connection-api/user/storeModule'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      optionsLocal: {},
      userData: JSON.parse(localStorage.getItem('userData')),
      userId: null,
    }
  },
  unmounted() {
    const USER_APP_STORE_MODULE_NAME = 'pengguna'
    if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
  },
  async mounted() {
    if (this.$route.params.id) {
      this.userId = this.$route.params.id
    } else {
      this.userId = this.userData.id
    }
    this.reloadData()
  },
  methods: {
    reloadData() {
      const USER_APP_STORE_MODULE_NAME = 'pengguna'
      // Register module
      if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)

      store.dispatch('pengguna/fetchDataKeterampilan', { id: this.userId })
        .then(response => {
          this.optionsLocal = response.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.optionsLocal = {}
          }
        })
    },
    pilihKeterampilan(jenisKeterampilanId, kategoriJenisKeterampilanId) {
      const localdata = {
        user_id: this.userId,
        jenis_keterampilan_id: jenisKeterampilanId,
        kategori_jenis_keterampilan_id: kategoriJenisKeterampilanId,
      }
      store.dispatch('pengguna/addDataKeterampilan', localdata)
        .then(response => {
          this.reloadData()
        })
        .catch(error => {
          if (error.response.status === 404) {
            // console
          }
        })
    },
    hapusKeterampilan(jenisKeterampilanId, kategoriJenisKeterampilanId) {
      const localdata = {
        user_id: this.userId,
        jenis_keterampilan_id: jenisKeterampilanId,
        kategori_jenis_keterampilan_id: kategoriJenisKeterampilanId,
      }
      store.dispatch('pengguna/removeDataKeterampilan', localdata)
        .then(response => {
          this.reloadData()
        })
        .catch(error => {
          if (error.response.status === 404) {
            // console
          }
        })
    },
  },
  beforeCreate() {
    // this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  },
}
</script>
